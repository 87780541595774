export default {
  text: [
    '一期可提',
    '余额',
    '释放',
    '总数据',
    '一期',
    '收益及奖励',
    '查看收益明细',
    '转二期质押',
    '总量',
    '质押',
    '空投',
    '质押收益',
    '平级奖',
    '分享奖励',
    '辅助奖励',
    '节点补助'
  ],
}