import right from './right';

export default {
  right,
  menu: [
    'Official website',
    'On-chain financial management to earn coinsin financial management to earn coins',
    'Decentralized currency exchange',
    'Decentralized Lending Exchange',
    'Decentralized Contract Exchange',
    'Decentralized Identity System',
    'Decentralized application system',
    'metaverse',
  ],
  toast: {
    text: ['Feature coming soon!'],
  },
};
