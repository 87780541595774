export default {
  outflows: {
    title: '等待買家付款',
    pay_text: '買家正在向您轉賬，請注意查收',
    sell: '出售 ',
    backbone: '交易主鏈',
    wallet: '交易錢包',
    copy: '錢包地址已複製',
    merchant: '商家錢包',
    copy_merchant: '商家錢包地址 已複製',
    copy_contract: '合約地址 已複製',
    contract: '合約地址',
    price: '單價',
    count: '數量',
    total_copy: '總金額 已複製',
    total: '總金額',
    proceeds: '收款方式',
    transaction: '現金交易',
    select: '請選擇收款方式',
    shroff_account: '收款賬號',
    name: '賣家暱稱',
    contact_seller: '聯繫賣家',
    number: '訂單編號',
    placeTime: '下單時間',
    sell_order: '出售訂單編號 已複製',
    create_order: '生成訂單編號 已複製',
    way: '選擇收款方式',
    hinf: [
      '請使用本人實名賬戶進行收款',
      ',否則會導致訂單失敗且賬號存在被凍結風險',
    ],
    merchant2: '商家提示:',
    chant_mass: '商家付款需要一定時間，請耐心等待',
    transfer: '轉幣中',
    transfer_text: '正在轉幣到合約，請稍等...',
    cencel_order: '取消訂單',
    contract2: '轉幣到合約',
    wechat_pay: '微信支付',
    Alipay: '支付寶',
    wechat: '微信',
    bank_card: '銀行卡',
    money: '現金',
    unknown_way: '未知收款方式',
    toast: [
      '您沒有填寫當前收款方式，',
      '請重新選擇',
      '請選擇您的收款方式',
      '您的訂單已完成付款，請等待賣家確認',
      '已轉幣至合約，請等待買家付款',
      '請重新選擇收款方式!!',
      '正在取消該訂單。。。',
      '  號訂單重複取消',
      '  號訂單已取消',
      '  號訂單已自動取消',
    ],
    network: '網絡&出現&問題',
    dialog: ['取消訂單', '頻繁取消訂單會被 【限制下單】', '確定取消訂單嗎？'],
    mistake: '地址出現錯誤',
  },
  details: {
    title: '買家已付款',
    pay_text: '買家已標記為轉賬成功，請確認收款',
    sell: '出售 ',
    backbone: '交易主鏈',
    wallet: '交易錢包',
    copy: '錢包地址 已複製',
    merchant: '商家錢包',
    copy_merchant: '商家錢包地址 已複製',
    copy_contract: '合約地址 已複製',
    contract: '合約地址',
    price: '單價',
    count: '數量',
    total_copy: '總金額 複製成功',
    total: '總金額',
    proceeds: '收款方式',
    transaction: '現金交易',
    service: '手續費',
    money: '現金',
    payer: '付款賬號',
    name: '賣家暱稱',
    number: '訂單編號',
    copy_order: '訂單編號 已複製',
    placeTime: '下單時間',
    Riskwarning: '買家轉賬時備註或您收到非實名匯款，請聯繫賣家退回或發起仲裁',
    toast: [
      '仲裁功能暫未開放，請等待！',
      '我已收到買家付款：',
      ' 釋放完成，成功出售！',
      '區塊繁忙。。釋放過程出現錯誤！',
      ' 釋放請稍後重試！',
    ],
    appeal: '仲裁',
    paymentInfo: '我已收到付款',
    sure: '確認收款',
    notice_text:
      '請去第三方平臺仔細核對以下收款信息，點擊確認收款，合約將放幣給對方。',
    network: '地址&獲取&錯誤',
    thirdparty: '1.打開第三方收款賬號',
    account_of: '2.確認到賬金額',
    three: '3.我確認付款人信息',
    money_copy: '到賬金額 複製成功',
    payment_way: ' 付款方式：現金交易',
    payment_name: '付款人姓名：',
    label: '我已確認【收到買家付款】',
    cencel_foot: '暫未收到',
    send: '放幣中',
    moment: '正在放幣給對方，請稍等...',
  },
  waterbill: {
    appeal: '申訴',
    paccount: '待收款',
    press: '催付款',
    already: '已催付款',
    water_text:
      '收款請認準訂單詳情中展示的買家收款賬號，或買家分享的付款賬號卡片。其餘買傢俬聊發送的賬號信息一律不要接受。',
    isSend: ['連線中', '發送'],
    uploadImg: '請上傳圖片',
    upload: '上傳',
    word: '第',
    page: '頁',
    message: ['發送內容不能為空', '加載中'],
    toast: [
      '請至少上傳一張圖片！',
      '圖片上傳失敗，請稍後上傳！',
      '已發送郵件至對方郵箱！',
      '請不要頻繁催促對方！！！',
    ],
    cinit_mes: '我轉幣至合約，請儘快完成訂單支付！',
    warn: ['您已斷開連接', '連接錯誤 error', '連接錯誤，斷開連接。。'],
    lian: '聯繫方式',
    first: '首次刷新數據',
  },
  sell: {
    win: '已成功出售',
    show_order: '查看訂單',
  },
};
