export default {
  title: '身份信息',
  button: '信用评分',
  list: [
    '姓名',
    '邮箱',
    '手机号',
    '证件号',
    '推荐人'
  ]
}