export default {
  buy: "购买",
  sell: "出售",
  all: "全部",
  default: "默认货币",
  search: "搜索",
  menu: [
    "订单",
    "委托单",
    "收付款设置",
    "新手引导",
    "关注",
    "黑名单",
    "系统语言",
    "常见问题",
    "在线客服"
  ]
}