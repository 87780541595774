export default {
  order: {
    sell: "出售",
    purchase: "收购",
    verify: "校验",
    canceled: "已取消",
    copy: "订单编号 已复制",
    number: "订单编号",
    price: "价格",
    count: "数量",
    service_charge: "手续费",
    total: "总金额",
    show: "展示完整信息",
    name: "商家姓名",
    way: "联系方式",
    phone: "电话号码 复制成功",
    select: "选择收款方式",
    may: "可现金交易",
    deal: "现金交易",
    self: "请使用本人实名账户进行收款",
    generate: ",否则会导致订单失败且账号存在被冻结风险",
  },
  comOrder: {
    sell: "出售",
    buyer: "联系买家",
    purchase: "收购",
    seller: "联系卖家",
    copy: "订单编号 已复制",
    number: "订单编号",
    price: "价格",
    count: "数量",
    service_charge: "手续费",
    total: "总金额",
    show: "展示完整信息",
    name: "商家姓名",
    way: "联系方式",
    phone: "电话号码 复制成功",
    plate: "电话号码",
    toast: "复制失败，请稍后重试",
  },
  undone: {
    sell: "出售",
    purchase: "收购",
    verify: "校验",
    Velocity: "去转币",
    payment: "去付款",
    Stay: "待放币",
    wait: "等待商家付款",
    put: "待收币",
    copy: "订单编号 已复制",
    number: "订单编号",
    price: "价格",
    count: "数量",
    service_charge: "手续费",
    total: "总金额",
    show: "展示完整信息",
    name: "商家姓名",
    way: "联系方式",
    phone: "电话号码 复制成功",
    may: "可现金交易",
    pattern: "付款方式",
    deal: "交易",
    cash: "现金",
    alipay: "支付宝",
    wechat: "微信",
    account: "收款账号",
    select: "选择收款方式",
    cash_deal: "现金交易",
    self: "请使用本人实名账户进行收款",
    generate: ",否则会导致订单失败且账号存在被冻结风险",
    toast: [
      "订单已过期，系统将自动取消。",
      "操作过于频繁，请稍候再试...",
      "已自动取消，到期订单",
    ],
  },
  refunds: {
    sell: "出售",
    purchase: "收购",
    verify: "校验",
    Velocity: "去转币",
    payment: "去付款",
    take_out: "取出",
    number: "订单编号",
    price: "价格",
    count: "数量",
    service_charge: "手续费",
    total: "总金额",
    show: "展示完整信息",
    name: "商家姓名",
    way: "联系方式",
    phone: "电话号码 复制成功",
    copy: "订单编号 已复制",
    may: "可现金交易",
    pattern: "付款方式",
    deal: "交易",
    cash: "现金",
    alipay: "支付宝",
    wechat: "微信",
    account: "收款账号",
    toast: [
      "订单取消成功！",
      "正在从合约中撤出 USDT。。。",
      "订单已过期，系统将自动取消。",
      "USDT 已撤出,订单已取消！",
      "操作过于频繁，请稍候再试...",
      "已自动取消，到期订单",
    ],
    dialog: ["提取 USDT", "恶意操作将会被 【限制下单】", "确定从该订单中撤回"],
  },
  full: {
    unfinished: "未完成",
    finished: "已完成",
    other: "其他订单",
  },
  empty: {
    not_data: "暂无数据",
  },
};
