export default {
  arbitrationMsg: {
    title: "仲裁案",
    plaintiff: "原告",
    seller: "(賣家)",
    ticket: "票",
    defendant: "被告",
    Buyer: "(買家)",
    user_content: ["賬戶被凍結", "賣家未確認收款", "其他"],
    orders: "仲裁詳情",
    notice_title: "仲裁結果",
    notice_bot: [
      "本次參與仲裁事件的仲裁員共計",
      "人,通過雙方提交舉證,",
      "位仲裁員判定被告dsads",
    ],
    detail: "詳情",
    applicant: "申請人",
    reason: "申請原因",
    explain: "申請延期說明",
    time: "申請延期時間",
    day: "天",
    cancel: "取消原因",
    plain_evidence: "原告舉證",
    defend_evidence: "被告舉證",
    Adduce_evidence: "追加舉證",
    Sponsor: "發起人",
    number: "編號",
    plain: "說明",
    Closure:
      "該仲裁案已結案，如有異議可在結案後七日內進入詳情申請再仲裁，注意逾期將無法隊此案進行再仲裁",
    again: "申請再次仲裁",
    messageType: [
      "申請延期",
      "追加舉證",
      "發起重新舉證",
      "取消仲裁",
      "結案通知",
    ],
  },
  index: {
    title: "仲裁案動態",
    messageType: ["申請", "通知"],
    reason: "近期訂單中存在糾紛，對方向你發起仲裁，請及時前往處理",
    description: "暫無數據",
    before_second: "秒鐘前",
    before_minute: "分鐘前",
    before_day: "天前",
  },
};
