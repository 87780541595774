export default {
  buy: "購買",
  sell: "出售",
  all: "全部",
  default: "默認貨幣",
  search: "搜索",
  menu: [
    "訂單",
    "委託單",
    "收付款設置",
    "新手引導",
    "關注",
    "黑名單",
    "系統語言",
    "常見問題",
    "在線客服"
  ]
}