export default {
  label: [
    '官方网站',
    '官方电报',
    '官方推特',
    '我的推荐人',
    '电话',
    '邮箱',
    '电报群'
  ],
  copy: {
    success: '复制成功',
    fail: '复制失败',
  }
}