export default {
  title: '取消仲裁',
  event: {
    text: '原告卖家发起仲裁，仲裁事件为'
  },
  delay: '申请延期',
  detail: '仲裁详情',
  empty: '暂无数据',
  loading: {
    text: '加载中…',
    fail: '加载失败！'
  }
}