export default {
  text: [
    '出售',
    '单价',
    '刷新'
  ],
  tab: [
    '按金额出售',
    '按数量出售'
  ]
}