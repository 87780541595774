export default {
  tags1:'交易總量',
  tags2:'總交易量',
  tags3:'萬',
  tags4:'總交易人數',
  tags5:'人',
  tags6:'交易數據圖表',
  tags7:'USDT購買出售情況',
  tags8:'交易量',
  tags9:'人數',
  title1:'交易數據',
  data1:["購買(USDT)", "出售(USDT)"],
  data2:'購買(USDT)',
  data3:'出售(USDT)'
}