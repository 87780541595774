export default {
  label: [
    '官方網站',
    '官方電報',
    '官方推特',
    '我的推薦人',
    '電話',
    '郵箱',
    '電報群',
  ],
  copy: {
    success: '複製成功',
    fail: '複製失敗',
  },
};
