export default {
  tags1: '团队人数',
  tags2: '直推人数',
  tags3: '有效节点',
  tags4: '直推业绩',
  tags5: '总业绩',
  tags6: '姓名',
  tags7: '未认证',
  tags8: '直推节点',
  tags9: '节点类型',
  tags10: '节点级别',
  tags11: '注册日期',
  tags12: '更多节点',
  tags13: '暂无团队人员',
  tags14: '手机号',
  tags15: '邮 箱',
  tags16: '一期质押',
  tags17: '给他转账',
  tags18: '查看更多会员请联系EOTC DAO',
  data1: '未质押',
  // more
  tags19: '请输入搜索关键词',
  tags20: '直推节点',
  node_type: '节点类型',
  node_grade: '节点级别',
  more_date: '注册日期',
  more_eotc: 'EOTC质押',
  more_usdt: 'USDT余额',
  more_node: '更多节点',
  // record
  record_tags1: '转入(出)UID',
  record_tags2: 'USDT数量',
  record_date: '转账日期',
  record_not_more: '没有更多了',
  record_data1: '转账记录',
  // transfer
  transfer_wallet: '钱包地址',
  transfer_wallet_tab: '点击选择钱包地址',
  transfer_currency: '选择币种',
  transfer_currency_tab: '点击选择币种',
  transfer_accounts: '转账数量',
  transfer_record: '查看转账记录',
  transfer_data1: '转账',
  transfer_data2: '可转账',
  transfer_data3: '请先选择币种！',
  transfer_data4: '请输入合法的数量！',
  transfer_data5: '您的钱包余额不足！',
}