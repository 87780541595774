export default {
  description: '暂无订单信息',
  deng: '等待收币',
  yi: '已收币',
  ordernum: '订单号',
  jiaoyinum: '交易数量',
  price: '交易单价',
  tprice: '交易总价',
  subtime: '提交时间',
  receipt: '收款信息',
  relation: '联系用户',
  receipt_type: '收款方式',
  cash: '现金交易',
  cash2: '现金',
  alipaynum: '支付宝账号',
  wechatnum: '微信号',
  banknum: '银行卡号',
  unknow_name: '未知姓名',
  unknow_way: '未知方式',
  unknow: '未知',
  // BuyBlanketOrder
  order_zong: '总订单号',
  order_price: '单价',
  order_num: '数量',
  order_xiane: '限额',
  order_time: '更新时间',
  order_cancel: '已取消',
  order_xiajia: '暂时下架',
  order_shangjia: '继续上架',
  order_order: '收购单',
  order_xiangou: '限购',
  order_in: '输入金额不正确',
  order_update: '更新订单',
  order_cancel_order: '取消订单',
  order_cancel_tip: '取消提示',
  order_whether: '是否取消该收购订单？',
  order_huifu: '取消后，订单不能恢复！',
  order_alter: '订单修改成功',
  order_warn: '输入金额数量不正确，请重新输入',
  order_quxiao: '订单已经取消',
  order_xiajia_tip: '下架提示',
  order_msg1: '暂时下架交易后会暂时停止对外收购，',
  order_msg2: '是否暂时下架该笔订单',
  order_xiajia2: '下架',
  order_xiajia3: '订单已下架',
  order_shangjia_tip: '上架提示',
  order_msg3: '上架后订单可正常进行交易，',
  order_msg4: '是否上架该笔订单',
  order_shangjia2: '上架',
  order_suc: '订单已上架，可正常进行交易',
  order_warn2: '该货币价格不能低于 5 CNY',
  order_warn3: '该货币价格不能高于 7.5 CNY',
  order_warn4: '您最高收购 USDT 的数量不能超过质押的数量',
  // BuyPending
  pending: '暂无订单信息',
  pending_duifang: '对方质押数量',
  pending_shoukuan: '卖家收款方式',
  pending_jianyan: '请先校验合约',
  pending_xiangxi: '详细信息',
  pending_name: '收款方姓名',
  pending_account: '收款账号',
  pending_confirm: '确认已付款',
  pending_shangwei: '尚未付款就点击',
  pending_wancheng: '【我已完成转账】',
  pending_shiwei: '将被视为恶意操作，',
  pending_eyi: '恶意操作会冻结用户',
  pending_jine: '转账金额',
  pending_me: '我已确认',
  pending_zhuan: '我已完成转账',
  pending_think: '我再想想',
  pending_zhongcai: '该订单正在仲裁中！',
  pending_heyue: '合约校验中，请等待...',
  pending_heyue2: '合约校验通过,可以给对方进行汇款！',
  pending_yichang: '订单异常',
  pending_yue: '合约中订单余额为',
  pending_site: '订单绑定的地址为',
  pending_copy: '账号 已复制',
  pending_error: '请稍后重试',
  // BuyReceivedCoin
  coin: '加入黑名单',
  // SellAccomplish
  lish: '已收款',
  lish_shensu: '申诉',
  lish_error1: '获取服务端数据错误！',
  lish_warn1: '申诉功能暂未开放，请耐心等待！',
  // SellBlankerOrder
  sorder:'校验',
  sorder_chushou:'出售单',
  sorder_xianshou:'限售',
  sorder_zhuijia:'追加数量',
  sorder_cancel:'是否取消该出售订单？',
  sorder_num:'请输入取消数量',
  sorder_all:'全部',
  sorder_ipt:'请输入数量',
  sorder_suc:'订单数量追加成功',
  sorder_input:'请输入0',
  sorder_input2:'的数量！',
  sorder_jianyan:'校验中...',
  // SellPending
  spending:'释放 USDT',
  spending_dengdai:'等待用户进行汇款！',
}