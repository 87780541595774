export default {
  shouyi: '二期可支配收益',
  kapai: '卡牌',
  zhiya: '交易質押',
  bibizhiya: 'OTC 幣幣質押明細',
  tochakan: '去查看',
  zhuanbi: '鏈上理財賺幣',
  shouyimingxi: '質押收益明細',
  tuiguang: '推廣收益明細',
  wakuang: '流動性挖礦',
  tixian: '提現',
  chongzhi: '充值',
  youxiao_user: '有效用戶',
  xinyong_node: '信用節點',
  shishi_node: '實時節點',
  zhongji_node: '中級節點',
  gaoji_node: '高級節點',
  youke: '遊客',
  // mining
  mining_shouyi: '推廣總收益',
  mining_zhitui: '直推收益',
  mining_jiantui: '間推收益',
  mining_shequ: '社區補貼',
  mining_jiedian: '節點補貼',
  mining_rule: '流動性挖礦規則',
  mining_p1: '質押EOTC\USDT交易對LP憑證6個月',
  mining_p2: '100-4999個雙幣質押LP憑證 正常年化收益',
  mining_p3: '5000-49999個雙幣質押LP憑證 1.2倍年化收益',
  mining_p4: '50000個及以上雙幣質押LP憑證 1.4倍年化收益',
  mining_p5: '年化收益計算按雙幣質押LP憑證個數乘以年化收益率，獎勵EOTC代幣',
  mining_p6: '質押EOTCUSDT交易對LP憑證12-36個月',
  mining_p7: '6個月收益48%',
  mining_p8: '12個月收益72%',
  mining_p9: '24個月收益100%',
  mining_p10: '收益根據二級市場價格自動調整',
  mining_p11: '流動性挖礦推廣獎勵',
  mining_p12: '質押EOTC\USDT交易對LP憑證6-24個月到DAPP，用戶和上級獲得EOTC代幣獎勵',
  mining_p13: '直推收益的20%',
  mining_p14: '間推收益的5%',
  mining_p15: '社區補貼 收益的1%向上分十代社區',
  mining_p16: '節點補貼 1、高級節點15%',
  mining_p17: '2、中級節點13%',
  mining_p18: '3、實時節點10%',
  mining_p19: '4、信用節點5%',
  // NFT
  nft_share: '分享獎勵',
  nft_fuzhu: '輔助獎勵',
  nft_shequ: '社區獎勵',
  nft_manghe: '盲盒獎勵(個)',
  nft_kapai: '卡牌獎勵(個)',
  nft_eotc: 'EOTC資金池',
  nft_heyue: '合約分配',
  nft_usdt: 'USDT資金池',
  nft_jiaoyi: '交易手續費',
  // recharge
  recharge_type: '充值類別',
  recharge_net: '充值網絡',
  recharge_site: '充值地址',
  recharge_num: '充值數量',
  recharge_pla: '請輸入充值數量',
  recharge_wancheng: '完成充值',
  recharge_recode: '查看充值記錄',
  recharge_data1: '充值功能尚未開放！',
  recharge_data2: '充值成功!',
  recharge_data3: '您充值的金額將在3分鐘之內到賬.',
  recharge_your: '您的',
  recharge_data4: '餘額不足！',
  // rechargeRecord
  record_statu: '當前狀態',
  record_date: '充值日期',
  record_not: '暫無收益數據',
  record_title: '充值記錄',
  record_loading: '加載中',
  record_confing: '確認中',
  record_wancheng: '已完成',
  record_suc: '加載成功',
  record_fail: '加載失敗',
  // Withdraw
  withdraw_type: '提現類別',
  withdraw_net: '網絡類型',
  withdraw_site: '提現地址',
  withdraw_num: '提現數量',
  withdraw_pla: '請輸入提現數量',
  withdraw_all: '全部',
  withdraw_fee: '手續費',
  withdraw_sub: '提交',
  withdraw_min: '最少提100',
  withdraw_time: '提幣到賬時間為T+1。',
  withdraw_warn: '功能暫未開放，請耐心等待。',
  withdraw_warn2: '您的EOTC餘額不足！',
  withdraw_warn3: '您的USDT餘額不足！',
  withdraw_load: '加載中...',
  withdraw_suc: '提現成功，您提現的金額將在5分鐘之內到賬',
  // WithdrawRecord
  wrecord_date: '提現日期',
  wrecord_record: '提現記錄',
  wrecord_zhiya: '質押收益',
  wrecord_buzhu: '節點補助',
  wrecord_pingji: '平級獎',
  wrecord_ewai: '額外獎勵',
  // zyzb
  zyzb_dingqi: '定期質押',
  zyzb_lixi: '利息收益',
  zyzb_tuijian: '推薦質押(人數)',
  zyzb_zongshu: '推薦質押總量(EOTC)',
  zyzb_rule: '盲盒贈送規則',
  zyzb_p1: '用戶質押1000-1999EOTC贈送推薦人1個盲盒',
  zyzb_p2: '用戶質押2000-2999EOTC贈送推薦人3個盲盒',
  zyzb_p3: '用戶質押3000-3999EOTC贈送推薦人4個盲盒',
  zyzb_p4: '用戶質押4000-4999EOTC贈送推薦人6個盲盒',
  zyzb_p5: '用戶質押5000EOTC以上贈送推薦人10個盲盒',
  zyzb_p6: '定期質押六個月',
  zyzb_p7: '1000-1999EOTC贈送用戶1個盲盒',
  zyzb_p8: '2000-2999EOTC贈送用戶3個盲盒',
  zyzb_p9: '3000-3999EOTC贈送用戶4個盲盒',
  zyzb_p10: '4000-4999EOTC贈送用戶6個盲盒',
  zyzb_p11: '5000EOTC贈送用戶10個盲盒',
  zyzb_p12: '5000EOTC以上每質押多500EOTC則加送用戶1個盲盒',
  zyzb_p13: '定期質押十二個月',
  zyzb_p14: '1000-1999EOTC贈送用戶2個盲盒',
  zyzb_p15: '2000-2999EOTC贈送用戶5個盲盒',
  zyzb_p16: '3000-3999EOTC贈送用戶7個盲盒',
  zyzb_p17: '4000-4999EOTC贈送用戶10個盲盒',
  zyzb_p18: '5000EOTC贈送用戶13個盲盒',
  zyzb_p19: '5000EOTC以上每質押多400EOTC則加送用戶1個盲盒',
  zyzb_p20: '定期質押二十四個月',
  zyzb_p21: '1000-1999EOTC贈送用戶3個盲盒',
  zyzb_p22: '2000-2999EOTC贈送用戶6個盲盒',
  zyzb_p23: '3000-3999EOTC贈送用戶9個盲盒',
  zyzb_p24: '4000-4999EOTC贈送用戶12個盲盒',
  zyzb_p25: '5000EOTC贈送用戶14個盲盒',
  zyzb_p26: '5000EOTC以上每質押多300EOTC則加送用戶1個盲盒',
  zyzb_sub: '鏈上理財質押',
  zyzb_title: '鏈上質押賺幣',
  // pledge(index)
  pledge_zong: '質押總額',
  pledge_ming: '質押明細',
  pledge_mian: '免手續費額度',
  pledge_zen: '贈送質押',
  pledge_shou: '手續費分紅',
  pledge_nft: 'NFT權益卡',
  pledge_zen_nft: '贈送NFT卡牌',
  pledge_rule: '質押鏈上節點規則',
  pledge_jiaoyi: '交易用戶',
  pledge_p1: '1、信用分10分以上',
  pledge_youxiao: '有效用戶(滿足以下條件之一)',
  pledge_p2: '1、質押100EOTC及以上',
  pledge_p3: '2、定期理財質押1000EOTC及以上',
  pledge_p4: '3、持有EOTC NFT任意一張卡牌及以上',
  pledge_p5: '4、質押EOTC LP 100個及以上',
  pledge_p6: '5、EOTC去中心化OTC交易所30天內交易三次及以上',
  pledge_xinyong: '信用節點',
  pledge_p7: '1、需要至少有5個直推有效用戶',
  pledge_p8: '2、質押代幣數量5000個以上',
  pledge_p9: '3、團隊有效用戶90人',
  pledge_shishi: '實時節點',
  pledge_p10: '1、需要至少有7個直推有效用戶',
  pledge_p11: '2、質押代幣數量10000個以上',
  pledge_p12: '3、團隊二條線有信用節點',
  pledge_p13: '4、團隊有效用戶300人',
  pledge_zhongji: '中級節點',
  pledge_p14: '1、需要至少有13個直推有效用戶',
  pledge_p15: '2、質押代幣數量前101-1100名',
  pledge_p16: '3、團隊三條線有實時節點或實時節點永久分紅權益卡',
  pledge_p17: '4、團隊有效用戶900人',
  pledge_p18: '5、至少質押代幣數量50000個以上',
  pledge_gaoji: '高級節點',
  pledge_p19: '1、需要至少有19個直推有效用戶',
  pledge_p20: '2、質押代幣數量前100名',
  pledge_p21: '3、團隊三條線有中級節點或中級節點永久分紅權益卡',
  pledge_p22: '4、團隊有效用戶3000人',
  pledge_p23: '5、至少質押代幣數量100000個',
  pledge_p24: '提示:今日鏈上理財賺幣中定期質押(6個月、12個月、24個月)總計超過1000EOTC,次日即可減免25%的去中心化幣幣交易所手續費。',
  pledge_zhiya: '交易質押',
  pledge_shuhui: '贖回',
  pledge_lian: '鏈上理財賺幣',
  pledge_otc: 'OTC質押',
  pledge_shouyi: '質押收益',
  // pledge(from)
  from_account: '質押賬戶',
  from_zong: 'EOTC總量',
  from_qixian: '質押期限',
  from_tab: '請選擇',
  from_num: '質押數量',
  from_pla: '請輸入質押數量',
  from_tip: '提示:質押6個月年化收益48%，質押12個月年化收益72%，質押24個月年化收益100%，質押36個月年化收益120%',
  from_node_type: '選擇節點類型',
  from_type: '節點類型',
  from_columns: [
    "有效用戶 100EOTC",
    "有效用戶 1000EOTC",
    "信用節點 5000EOTC",
    "實時節點 10000EOTC",
    "中級節點 50000EOTC",
    "高級節點 100000EOTC",
  ],
  from_column: ["6個月", "12個月", "24個月", "36個月"],
  from_youxiao: '請輸入有效的質押數量',
  from_acc_buzu: '您的EOTC餘額不足',
  from_fail: '質押失敗！',
  from_suc: '質押成功！',
  from_months:'個月',
  from_cancel:'取消',
  // pledge(record)
  ple_record_leiji:'累計收益',
  ple_record_dangqian:'當前質押',
  ple_record_shuhui:'待贖回',
  ple_record_order:'訂單編號',
  ple_record_daoqi:'質押到期',
  ple_record_yugu:'預估收益',
  ple_record_keshuhui:'到期可贖回',
  ple_record_title:'質押記錄',
  ple_record_zhiyaing:'質押中',
  // pledge(shfrom)
  shfrom_shuhui:'質押贖回',
  shfrom_num:'贖回數量',
  shfrom_num2:'可贖回 0 EOTC',
  shfrom_tip:'贖回提示',
  shfrom_cai:'質押六個月以後才可以贖回',
  shfrom_iknow:'我知道了',
  
}