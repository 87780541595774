export default {
  outflows: {
    title: "等待买家付款",
    pay_text: "买家正在向您转账，请注意查收",
    sell: "出售 ",
    backbone: "交易主链",
    wallet: "交易钱包",
    copy: "钱包地址已复制",
    merchant: "商家钱包",
    copy_merchant: "商家钱包地址 已复制",
    copy_contract: "合约地址 已复制",
    contract: "转币到合约",
    price: "单价",
    count: "数量",
    total_copy: "总金额 已复制",
    total: "总金额",
    proceeds: "收款方式",
    transaction: "现金交易",
    select: "请选择收款方式",
    shroff_account: "收款账号",
    name: "卖家昵称",
    contact_seller: "联系卖家",
    number: "订单编号",
    placeTime: "下单时间",
    sell_order: "出售订单编号 已复制",
    create_order: "生成订单编号 已复制",
    way: "选择收款方式",
    hinf: [
      "请使用本人实名账户进行收款",
      ",否则会导致订单失败且账号存在被冻结风险",
    ],
    merchant2: "商家提示:",
    chant_mass: "商家付款需要一定时间，请耐心等待",
    transfer: "转币中",
    transfer_text: "正在转币到合约，请稍等...",
    cencel_order: "取消订单",
    contract2: "转币到合约",
    wechat_pay: "微信支付",
    Alipay: "支付宝",
    wechat: "微信",
    bank_card: "银行卡",
    money: "现金",
    unknown_way: "未知收款方式",
    toast: [
      "您没有填写当前收款方式，",
      "请重新选择",
      "请选择您的收款方式",
      "您的订单已完成付款，请等待卖家确认",
      "已转币至合约，请等待买家付款",
      "请重新选择收款方式!!",
      "正在取消该订单。。。",
      "  号订单重复取消",
      "  号订单已取消",
      "  号订单已自动取消",
    ],
    network: "网络&出现&问题",
    dialog: ["取消订单", "频繁取消订单会被 【限制下单】", "确定取消订单吗？"],
    mistake: "地址出现错误",
  },
  details: {
    title: "买家已付款",
    pay_text: "买家已标记为转账成功，请确认收款",
    sell: "出售 ",
    backbone: "交易主链",
    wallet: "交易钱包",
    copy: "钱包地址 已复制",
    merchant: "商家钱包",
    copy_merchant: "商家钱包地址 已复制",
    copy_contract: "合约地址 已复制",
    contract: "合约地址",
    price: "单价",
    count: "数量",
    total_copy: "总金额 复制成功",
    total: "总金额",
    proceeds: "收款方式",
    transaction: "现金交易",
    service: "手续费",
    money: "现金",
    payer: "付款账号",
    name: "卖家昵称",
    number: "订单编号",
    copy_order: "订单编号 已复制",
    placeTime: "下单时间",
    Riskwarning: "买家转账时备注或您收到非实名汇款，请联系卖家退回或发起仲裁",
    toast: [
      "仲裁功能暂未开放，请等待！",
      "我已收到买家付款：",
      " 释放完成，成功出售！",
      "区块繁忙。。释放过程出现错误！",
      " 释放请稍后重试！",
    ],
    appeal: "仲裁",
    paymentInfo: "我已收到付款",
    sure: "确认收款",
    notice_text:
      "请去第三方平台仔细核对以下收款信息，点击确认收款，合约将放币给对方。",
    network: "地址&获取&错误",
    thirdparty: "1.打开第三方收款账号",
    account_of: "2.确认到账金额",
    three: "3.我确认付款人信息",
    money_copy: "到账金额 复制成功",
    payment_way: " 付款方式：现金交易",
    payment_name: "付款人姓名：",
    label: "我已确认【收到买家付款】",
    cencel_foot: "暂未收到",
    send: "放币中",
    moment: "正在放币给对方，请稍等...",
  },
  waterbill: {
    appeal: "申诉",
    paccount: "待收款",
    press: "催付款",
    already: "已催付款",
    water_text:
      "收款请认准订单详情中展示的买家收款账号，或买家分享的付款账号卡片。其余买家私聊发送的账号信息一律不要接受。",
    isSend: ["连线中", "发送"],
    uploadImg: "请上传图片",
    upload: "上传",
    word: "第",
    page: "页",
    message: ["发送内容不能为空", "加载中"],
    toast: [
      "请至少上传一张图片！",
      "图片上传失败，请稍后上传！",
      "已发送邮件至对方邮箱！",
      "请不要频繁催促对方！！！",
    ],
    cinit_mes: "我转币至合约，请尽快完成订单支付！",
    warn: ["您已断开连接", "连接错误 error", "连接错误，断开连接。。"],
    lian: "联系方式",
    first: "首次刷新数据",
  },
  sell: {
    win: "已成功出售",
    show_order: "查看订单",
  },
};
