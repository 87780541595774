export default {
  affirm: {
    title: "等待卖家确认收款",
    pay_text: "预计",
    away: "内收到",
    buy: "购买 ",
    number: "订单编号",
    price: "单价",
    count: "数量",
    total: "总金额",
    service: "手续费",
    payment: "我的付款方式",
    wechat_pay: "微信支付",
    Alipay: "支付宝",
    bank_card: "银行卡",
    transaction: "现金交易",
    merchant: "商家收款账号",
    seller: "卖家姓名",
    wallet: "商家钱包地址",
    look: "查看订单",
    appeal: "申诉",
    toast: "申诉功能暂未开放，请耐心等待",
  },
  payment: {
    hd_txt: "付款通知",
    wechat_pay: "请使用微信支付",
    transaction: "使用现金交易",
    bank_card: "请使用银行卡进行支付",
    Alipay2: "请使用支付宝支付",
    residue: "付款剩余时间",
    select: "您选择的是现金交易",
    open: "请打开您的微信",
    open_app: "请打开",
    open_alipay: "请打开您的支付宝",
    amount: "交易总额：",
    towards: "向商家转账：",
    fee: "交易手续费：",
    name: "姓名",
    account: "微信账号",
    way: "交易方式",
    deposit: "开户行",
    alipay_account: "支付宝账号",
    money: "现金交易",
    ID: "账号",
    pay_info: [
      "请使用本人实名账户进行付款",
      "，否则会导致订单失败",
      "且账号存在被冻结风险",
      "转账过程中，请勿备注任何信息",
      "为确保及时到账，大额数量的转账请分批转账",
      "转账完成后点击按钮",
      "我已转账，通知卖家",
    ],
    toast: [
      "正在取消该订单。。。",
      "  号订单重复取消",
      "  号订单已取消",
      "等待区块打包确认，",
      "打包期间请不要关闭或刷新该页面",
      "姓名 已复制",
      "请稍后重试",
      "复制 账号成功",
      "复制失败，网络故障",
    ],
    Alipay: "支付宝",
    wechat: "微信",
    cash: "现金",
  },
  tips: {
    title: "确认付款信息",
    message: "平台不会自动扣款，您需要打开第三方支付平台转账给卖家",
    info: "交易信息：",
    type: "交易类型：",
    wechat_pay: "微信支付",
    Alipay: "支付宝",
    bank_card: "银行卡",
    transaction: "现金交易",
    name: "对方姓名：",
    account: "对方收款账号：",
    finish: "我已经完成转账",
    unfinished: "我还未完成转账",
    van_form: [
      "确认付款",
      "尚未付款就点击",
      "【我已转账，通知卖家】",
      "将被视为恶意操作，冻结用户",
      "我已经完成转账",
      "我再想想",
    ],
    toast: "请点击同意确认付款协议。",
  },
  order: {
    title: "订单已经生成",
    residue: "付款剩余时间",
    hint: "卖家提示",
    buy: "购买 ",
    backbone: "交易主链",
    wallet: "交易钱包",
    copy: "钱包地址已复制",
    merchant: "商家钱包",
    copy_merchant: "商家钱包地址 已复制",
    copy_contract: "合约地址 已复制",
    contract: "合约地址",
    look_order: "合约查询订单",
    step: "指导查看步骤",
    price: "单价",
    count: "数量",
    total_copy: "总金额 已复制",
    total: "总金额",
    way: "卖家收款方式",
    account: "我的付款账号",
    transaction: "现金交易",
    number: "订单编号",
    number_copy: "大订单编号 已复制",
    create_copy: "生成订单编号 已复制",
    placeTime: "下单时间",
    cencel: "取消订单",
    next: "下一步",
    available: "暂时&未有地址",
    toast: [
      "等待商家通过对您的流水审查！",
      "商家已通过您的流水审查",
      "可进行下一步汇款",
      "网页连接已复制",
      "请在游览器中打开，按步骤查询！",
      "复制失败，请稍后重试",
      "正在取消该订单。。。",
      "  号订单重复取消",
      "  号订单已取消",
      "商家需要对您的账户进行流水审查",
      "审查结果通过后可进行下一步!",
    ],
    dialog: ["取消订单", "频繁取消订单会被 【限制下单】", "确定取消订单吗？"],
  },
  success: {
    account: "已存入您的资金账户",
    balance: "查看资产",
    order: "查看订单",
    toast: "USDC 已经到账，请查收",
  },
  coin: {
    release: "释放 ",
    title: "用户已付款",
    pay_text: "用户已标记为转账成功，请确认收款",
    sell: "出售 ",
    wallet: "交易钱包",
    copy: "钱包地址 已复制",
    merchant: "商家钱包",
    copy_merchant: "商家钱包地址 已复制",
    price: "单价",
    count: "数量",
    total_copy: "总金额 已复制",
    total: "总金额",
    proceeds: "收款方式",
    Alipay: "支付宝",
    wechat: "微信",
    transaction: "现金交易",
    money: "现金",
    shroff_account: "收款账号",
    name: "用户姓名",
    user: "用户付款方式",
    payer: "付款账号",
    number: "订单编号",
    order_copy: "订单编号 已复制",
    placeTime: "下单时间",
    Riskwarning: "收到买家转账时备注或您收到非实名汇款，请联系卖家退回。",
    verify: "我已收到付款",
    centre: "放币中",
    ld_text: "正在放币给对方，请稍等...",
    warning_tips: "点击确认收到付款后，合约将会放币给对方",
    unknown: "未知",
    toast: ["放币成功", "我已收到买家付款："],
  },
};
