export default {
  tags1: 'total transaction volume',
  tags2: 'total transaction volume',
  tags3: 'Ten thousand',
  tags4: 'total number of transactions',
  tags5: 'people',
  tags6: 'Trading Data Chart',
  tags7: 'USDT buying and selling',
  tags8: 'Trading volume',
  tags9: 'number of people',
  title1: 'transaction data',
  data1: ["Buy (USDT)", "Sell​(USDT)"],
  data2: 'Buy (USDT)',
  data3: 'Sell ​​(USDT)'
}