export default {
  status: ['認證失敗', '未認證', '審核中', '已認證', '未質押'],
  text: ['節點類型'],
  menu: [
    '認證審核',
    '二期推廣',
    '交易數據',
    '新手引導',
    '常見問題',
    '聯繫我們',
    '反饋&建議',
    '個人信息',
    '身份信息',
    '收付款信息',
    '各公鏈綁定地址信息',
    '推廣信息',
    '一期推廣',
    '分享鏈接',
    '團隊節點',
    '交易管理',
    '訂單',
    '委託單',
    '仲裁',
    '仲裁員',
    '關注/黑名單',
  ],
  toast: {
    text: [
      '審核中…',
      '您質押的',
      '不足',
      '質押',
      '以上的會員才能掛單',
      '您的積分不足',
      '擁有',
      '積分的會員才能掛單',
    ],
  },
};
