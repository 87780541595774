export default {
  exam: {
    title: "仲裁考试",
  },
  study: {
    title: "了解学习相关规则",
    text: "仲裁规则",
    finish: "我已完成学习",
    ruleList: [
      "规则1",
      "规则2",
      "规则3",
      "大大大是的大大大是的大大大是的大大大是的大大大是的大大大是的大大大是的",
    ],
  },
};
