export default {
  exam: {
    title: '仲裁考試',
  },
  study: {
    title: '瞭解學習相關規則',
    text: '仲裁規則',
    finish: '我已完成學習',
    ruleList: [
      '規則1',
      '規則2',
      '規則3',
      '大大大是的大大大是的大大大是的大大大是的大大大是的大大大是的大大大是的',
    ],
  },
};
