export default {
  label: [
    'Official website',
    'official telegram',
    'Official Twitter',
    'my recommenders',
    'Telephone',
    'Mail',
    'Telegram group',
  ],
  copy: {
    success: 'Copy successfully',
    fail: 'Replication failed',
  },
};
