export default {
  left_text: "返回",
  event_title: "申诉事件",
  text: "文字信息举证",
  lint: " 投诉提交后，客服会调取该订单的聊天记录。若有其他截图证据，请一并发送到聊天中！",
  submit: "提交",
  van_field: "描述具体情况及提供可核实情况所需信息",
  title: "发起申诉",
  day: ["联系不上商家", "付不了款", "其他"],
  message: "提交中...",
  success: ["付款中…", "支付失败！", "仲裁提交中…", "提交成功", "提交失败"],

  start: {
    content: ["仲裁", "申诉", "事件"],
    frozen: "账户被冻结",
    not: "卖家未确认收款",
    dissent: "仲裁异议",
    other: "其他",
    evidence: "文字信息举证",
    placeholder: "描述具体情况及提供可核实情况所需信息",
    upimg: ["图片信息举证", "(具有法律效力的相关举证)"],
    addFlex: ["增加仲裁员", "(默认", "人)"],
    pay: "需支付",
    pop: [
      "确认提交并支付",
      "每人仅可提交一次延期申请，请认真填写，是否确定提交该申请延期并支付?",
      "我已确认",
    ],
    handle: ["确定提交并支付", "我再想想"],
    apply: "申请再仲裁",
    message: ["上传中…", "上传成功", "上传失败"],
    notify: ["第", "张图片上传失败，请重新上传"],
  },
};
