export default {
  credit: {
    title: "信用评分",
    record: "加分记录",
    low: "信用评分低",
    box_left: "完成强关系链身份认证",
    fiel: "被推荐人陈粒非恶意交易失败",
    marks: "扣分记录",
  },
  message: {
    title: "信息设置",
    label: ["姓名", "商家名称", "联系电话", "出售提示", "收购提示"],
    placeholder: [
      "请输入商家名称",
      "请输入商家联系电话",
      "该提示将显示在交易对话框中",
    ],
    submit: "提交",
    toast: [
      "暂无商家基本信息",
      "信息设置修改成功",
      "服务器繁忙,请稍后重试！",
      "手机号码格式不对",
    ],
    max: "最大输入",
    byte: "个字节",
    fairly: "相当",
    Chinese: "个汉字",
  },
  chase: {
    title: "收购",
    number: "数量",
    placeholder: [
      "最大收购数量",
      "收购",
      "的单价(CNY)",
      "最低收购总额",
      "最高收购总额",
    ],
    price: "单价",
    cell_title: "限购",
    error_text: "输入金额不正确",
    deal: "使用现金交易：",
    adopt: "可接受现金",
    onlyAccept:'只接受现金',
    create: "生成收购订单",
    sure: "确定收购订单",
    current: "请确认当前订单信息",
    check: "我已认真核对",
    affirm: "确认生成",
    create_title: "收购单生成中,请耐心等待。。。",
    exceed: "挂单数量超过最大数额！",
    toast: "网络有点小错误，请稍后重试。",
  },
  sell: {
    sell: "出售",
    number: "数量",
    price: "单价",
    sales: "限售",
    error_text: "输入金额不正确",
    placeholder: [
      "最大出售数量",
      "出售",
      "的单价(CNY)",
      "最低出售总额",
      "最高出售总额",
    ],
    deal: "使用现金交易：",
    adopt: "可接受现金",
    onlyAccept:'只接受现金',
    create: "生成出售订单",
    sure: "确定出售订单",
    current: "请确认当前订单信息",
    quota: "限额",
    check: "我已认真核对",
    affirm: "确认生成",
    repaste: "合约转币",
    site: "合约地址",
    towards: "向合约转币",
    toast: ["挂单数量 超出限制！", "挂出售单过程发生错误"],
  },
};
