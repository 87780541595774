export default {
  order: {
    sell: "sell",
    purchase: "purchase",
    verify: "Acquisition",
    canceled: "Canceled",
    copy: "Order number copied",
    number: "Order No",
    price: "Price",
    count: "quantity",
    service_charge: "Service Charge",
    total: "Total amount",
    show: "Show complete information",
    name: "Merchant name",
    way: "contact information",
    phone: "Phone number copied successfully",
    select: "Select Collection Method",
    may: "Cash transaction available",
    deal: "Cash transactions",
    self: "Please use your real name account for collection",
    generate: "Otherwise, the order will fail and the account will be frozen",
  },
  comOrder: {
    sell: "sell",
    buyer: "Contact Buyer",
    purchase: "Acquisition",
    seller: "Contact Seller",
    copy: "Order number copied",
    number: "Order No",
    price: "Price",
    count: "quantity",
    service_charge: "Service Charge",
    total: "Total amount",
    show: "Show complete information",
    name: "Merchant name",
    way: "contact information",
    phone: "Phone number copied successfully",
    plate: "Telephone number",
    toast: "Replication failed, please try again later",
  },
  undone: {
    sell: "sell",
    purchase: "Acquisition",
    verify: "check",
    Velocity: "To transfer currency",
    payment: "To pay",
    Stay: "Currency to be released",
    wait: "Waiting for merchant payment",
    put: "Currency to be received",
    copy: "Order number copied",
    number: "Order No",
    price: "Price",
    count: "quantity",
    service_charge: "Service Charge",
    total: "Total amount",
    show: "Show complete information",
    name: "Merchant name",
    way: "contact information",
    phone: "Phone number copied successfully",
    may: "Cash transaction available",
    pattern: "payment method",
    deal: "transaction",
    cash: "cash",
    alipay: "Alipay",
    wechat: "WeChat",
    account: "Collection account No",
    select: "Select Collection Method",
    cash_deal: "Cash transactions",
    self: "Please use your real name account for collection",
    generate: "Otherwise, the order will fail and the account will be frozen",
    toast: [
      "The order has expired, and the system will automatically cancel it.",
      "The operation is too frequent, please try again later...",
      "Automatically cancelled, due order",
    ],
  },
  refunds: {
    sell: "sell",
    purchase: "Acquisition",
    verify: "check",
    Velocity: "To transfer currency",
    payment: "To pay",
    take_out: "take out",
    number: "Order No",
    price: "Price",
    count: "quantity",
    service_charge: "Service Charge",
    total: "Total amount",
    show: "Show complete information",
    name: "Merchant name",
    way: "contact information",
    phone: "Phone number copied successfully",
    copy: "Order number copied",
    may: "Cash transaction available",
    pattern: "payment method",
    deal: "transaction",
    cash: "cash",
    alipay: "alipay",
    wechat: "wechat",
    account: "Collection account No",
    toast: [
      "Order cancelled successfully!",
      "Withdrawing from the contract USDT。。。",
      "The order has expired, and the system will automatically cancel it.",
      "USDT Withdrawn, order cancelled!",
      "The operation is too frequent, please try again later...",
      "Automatically cancelled, due order",
    ],
    dialog: [
      "extract USDT",
      "Malicious operations will be 【 restricted order 】",
      "Confirm to withdraw from this order",
    ],
  },
  full: {
    unfinished: "hang in the air",
    finished: "Completed",
    other: "Other orders",
  },
  empty: {
    not_data: "No data",
  },
};
