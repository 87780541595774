export default {
  buy: 'Buy',
  sell: 'sell',
  all: 'all',
  default: 'default currency',
  search: 'search',
  menu: [
    'Order',
    'Order',
    'Receipt and Payment Settings',
    'Newbie Guide',
    'Follow',
    'Blacklist',
    'System Language',
    'FAQ',
    'Online Customer Service',
  ],
};
