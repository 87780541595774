export default {
  arbitrationMsg: {
    title: "Arbitration case",
    plaintiff: "plaintiff",
    seller: "(seller)",
    ticket: "ticket",
    defendant: "defendant",
    Buyer: "(Buyer)",
    user_content: [
      "Account frozen",
      "The seller did not confirm the collection",
      "other",
    ],
    orders: "Details of arbitration",
    notice_title: "Arbitration result",
    notice_bot: [
      "The total number of arbitrators involved in the arbitration event",
      "People, through proof submitted by both parties,",
      "Arbitrators awarded the defendant dsads",
    ],
    detail: "details",
    applicant: "applicant",
    reason: "Reason for application",
    explain: "Description of application for extension",
    time: "Application for extension of time",
    day: "day",
    cancel: "Reason for cancellation",
    plain_evidence: "The plaintiff adduced evidence",
    defend_evidence: "The defendant adduces evidence",
    Adduce_evidence: "Additional evidence",
    Sponsor: "Sponsor",
    number: "number",
    plain: "explain",
    Closure:
      "The arbitration case has been settled. If you have any objection, you can enter the details and apply for re arbitration within seven days after the settlement of the case. Please note that you will not be able to re arbitrate the case within the time limit",
    again: "Apply for re arbitration",
    messageType: [
      "Application for extension",
      "Additional evidence",
      "Initiate new proof",
      "Cancel arbitration",
      "Final notice",
    ],
  },
  index: {
    title: "Dynamics of arbitration cases",
    messageType: ["apply", "notice"],
    reason:
      "There is a dispute in the recent order, and the opposite party initiates arbitration for you. Please go to handle it in time",
    description: "No data",
    before_second: "Seconds ago",
    before_minute: "Minutes ago",
    before_day: "Days ago",
  },
};
