export default {
  exam: {
    title: "Arbitration examination",
  },
  study: {
    title: "Understand the rules related to learning",
    text: "arbitration rules",
    finish: "I have finished my study",
    ruleList: [
      "Rule1",
      "Rule2",
      "Rule3",
      "Big big Yes, big big Yes, big big Yes, big big Yes, big big Yes, big big Yes, big big Yes",
    ],
  },
};
