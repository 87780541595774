import pay from "./pay"
import sale from "./sale"
import dealPay from "./dealPay"
import saleDealPay from "./saleDealPay"

export default {
  pay,
  sale,
  dealPay,
  saleDealPay
}