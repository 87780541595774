export default {
  name: '姓 名',
  name_pla: '请输入真实姓名',
  kaihu: '开户银行',
  kaihui_pla: '请输入开户银行',
  banknum: '银行卡号',
  banknum_pla: '请输入银行卡账号',
  alipay: '支 付 宝',
  alipay_pla: '请输入支付宝账号',
  wechat: '微 信 号',
  wechat_pla: '请输入微信账号',
  bankaccount: '银行账号',
  renzheng: '我已认证核对',
  title: '设置收付款信息',
  msg: '名称不能为空',
  msg2: '名称需要两位字符以上！',
  msg3: '请输入正确的开户银行',
  msg4: '请输入正确的银行卡号码',
  msg5: '请输入正确的支付宝账号',
  msg6: '请输入正确的微信账号',
  msg7: '提币地址不能为空',
  msg8: '请输入正确的提币地址',
  msg9: '交易密码不能为空',
  msg10: '交易密码需要由字母和数字组成，且在8-16之间',
  warn: '暂不支持该收付款方式',
  toast: '银行信息请填写完整！',
  toast2: '请填写最少一种支付方式',
  toast3: '请输入银行卡的完整信息',
  tip: '温馨提示',
  msg11: '收付款信息涉及交易，请核对信息无误之后再进行提交',
  ok: '好的',
  msg12: '请确认当前信息无误',
  error: '设置失败，请重试。。。',
  toast4: '添加付款方式成功！',
  // list
  list_title:'收付款信息',
  list_set:'设置收付款账号',
  list_del:'删除',
  list_kaiqi:'向买家仅展示已开启的收款账户',
  list_any:'还没有任何收付款方式',
  list_serch:'搜索',
  list_def:'默认货币',
  list_all:'全部',
  list_res:'资源未找到',
  list_pay:["银行卡", "支付宝", "微信"],
  
}