export default {
  text: [
    '当前网络',
    '钱包地址',
    '输入邮箱地址',
    '发送验证码',
    '输入邮箱验证码',
    '登录',
    '立即注册'
  ],
}