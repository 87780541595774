export default {
  text: [
    'Current network',
    'Wallet address',
    'Enter email address',
    'Send verification code',
    'Enter email verification code',
    'Login',
    'Register now',
  ],
};
