export default {
  text: [
    '當前網絡',
    '錢包地址',
    '輸入郵箱地址',
    '發送驗證碼',
    '輸入郵箱驗證碼',
    '登錄',
    '立即註冊',
  ],
};
