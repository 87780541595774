export default {
  status: [
    '认证失败',
    '未认证',
    '审核中',
    '已认证',
    '未质押'
  ],
  text: [
    '节点类型'
  ],
  menu: [
    '认证审核',
    '二期推广',
    '交易数据',
    '新手引导',
    '常见问题',
    '联系我们',
    '反馈&建议',
    '个人信息',
    '身份信息',
    '收付款信息',
    '各公链绑定地址信息',
    '推广信息',
    '一期推广',
    '分享链接',
    '团队节点',
    '交易管理',
    '订单',
    '委托单',
    '仲裁',
    '仲裁员',
    '关注/黑名单'
  ],
  toast: {
    text: [
      '审核中…',
      '您质押的',
      '不足',
      '质押',
      '以上的会员才能挂单',
      '您的积分不足',
      '拥有',
      '积分的会员才能挂单',
    ]
  }
}