export default {
  affirm: {
    title: "等待賣家確認收款",
    pay_text: "預計",
    away: "內收到",
    buy: "購買 ",
    number: "訂單編號",
    price: "單價",
    count: "數量",
    total: "總金額",
    service: "手續費",
    payment: "我的付款方式",
    wechat_pay: "微信支付",
    Alipay: "支付寶",
    bank_card: "銀行卡",
    transaction: "現金交易",
    merchant: "商家收款賬號",
    seller: "賣家姓名",
    wallet: "商家錢包地址",
    look: "查看訂單",
    appeal: "申訴",
    toast: "申訴功能暫未開放，請耐心等待",
  },
  payment: {
    hd_txt: "付款通知",
    wechat_pay: "請使用微信支付",
    transaction: "使用现金交易",
    bank_card: "請使用銀行卡進行支付",
    Alipay2: "請使用支付寶支付",
    residue: "付款剩餘時間",
    select: "您选择的是现金交易",
    open: "請打開您的微信",
    open_app: "請打開",
    open_alipay: "請打開您的支付寶",
    amount: "交易總額：",
    towards: "向商家轉賬：",
    fee: "交易手續費：",
    name: "姓名",
    account: "微信賬號",
    way: "交易方式",
    deposit: "開戶行",
    alipay_account: "支付寶賬號",
    money: "現金交易",
    ID: "賬號",
    pay_info: [
      "請使用本人實名賬戶進行付款",
      "，否則會導致訂單失敗",
      "且賬號存在被凍結風險",
      "轉賬過程中，請勿備註任何信息",
      "為確保及時到賬，大額數量的轉賬請分批轉賬",
      "轉賬完成後點擊按鈕",
      "我已轉賬，通知賣家",
    ],
    toast: [
      "正在取消該訂單。 。 。",
      "  號訂單重複取消",
      "  號訂單已取消",
      "等待區塊打包確認，",
      "打包期間請不要關閉或刷新該頁面",
      "姓名 已復制",
      "請稍後重試",
      "複製 賬號成功",
      "複製失敗，網絡故障",
    ],
    Alipay: "支付寶",
    wechat: "微信",
    cash: "現金",
  },
  tips: {
    title: "確認付款信息",
    message: "平台不會自動扣款，您需要打開第三方支付平台轉賬給賣家",
    info: "交易信息：",
    type: "交易類型：",
    wechat_pay: "微信支付",
    Alipay: "支付寶",
    bank_card: "銀行卡",
    transaction: "現金交易",
    name: "對方姓名：",
    account: "對方收款賬號：",
    finish: "我已經完成轉賬",
    unfinished: "我還未完成轉賬",
    van_form: [
      "確認付款",
      "尚未付款就點擊",
      "【我已轉賬，通知賣家】",
      "將被視為惡意操作，凍結用戶",
      "我已經完成轉賬",
      "我再想想",
    ],
    toast: "請點擊同意確認付款協議。",
  },
  order: {
    title: "訂單已經生成",
    residue: "付款剩餘時間",
    hint: "賣家提示",
    buy: "購買 ",
    backbone: "交易主鏈",
    wallet: "交易錢包",
    copy: "錢包地址已復制",
    merchant: "商家錢包",
    copy_merchant: "商家錢包地址 已復制",
    copy_contract: "合約地址 已復制",
    contract: "合約地址",
    look_order: "合約查詢訂單",
    step: "指導查看步驟",
    price: "單價",
    count: "數量",
    total_copy: "總金額 已復制",
    total: "總金額",
    way: "賣家收款方式",
    account: "我的付款賬號",
    transaction: "現金交易",
    number: "訂單編號",
    number_copy: "大訂單編號 已復制",
    create_copy: "生成訂單編號 已復制",
    placeTime: "下單時間",
    cencel: "取消訂單",
    next: "下一步",
    available: "暫時&未有地址",
    toast: [
      "等待商家通過對您的流水審查！",
      "商家已通過您的流水審查",
      "可進行下一步匯款",
      "網頁連接已復制",
      "請在瀏覽器中打開,按步驟查詢!",
      "複製失敗，請稍後重試",
      "正在取消該訂單。 。 。",
      "  號訂單重複取消",
      "  號訂單已取消",
      "商家需要對您的賬戶進行流水審查",
      "審查結果通過後可進行下一步!",
    ],
    dialog: ["取消訂單", "頻繁取消訂單會被 【限制下單】", "確定取消訂單嗎？"],
  },
  success: {
    account: "已存入您的資金賬戶",
    balance: "查看資產",
    order: "查看訂單",
    toast: "USDC 已經到賬，請查收",
  },
  coin: {
    release: "釋放 ",
    title: "用戶已付款",
    pay_text: "用戶已標記為轉賬成功，請確認收款",
    sell: "出售 ",
    wallet: "交易錢包",
    copy: "錢包地址 已復制",
    merchant: "商家錢包",
    copy_merchant: "商家錢包地址 已復制",
    price: "單價",
    count: "數量",
    total_copy: "總金額 已復制",
    total: "總金額",
    proceeds: "收款方式",
    Alipay: "支付寶",
    wechat: "微信",
    transaction: "現金交易",
    money: "現金",
    shroff_account: "收款賬號",
    name: "用戶姓名",
    user: "用戶付款方式",
    payer: "付款賬號",
    number: "訂單編號",
    order_copy: "訂單編號 已復制",
    placeTime: "下單時間",
    Riskwarning: "收到買家轉賬時備註或您收到非實名匯款，請聯繫賣家退回。",
    verify: "我已收到付款",
    centre: "放幣中",
    ld_text: "正在放幣給對方，請稍等...",
    warning_tips: "點擊確認收到付款後，合約將會放幣給對方",
    unknown: "未知",
    toast: ["放幣成功", "我已收到買家付款："],
  },
};
