export default {
  label: [
    'Total revenue',
    'View income details',
    'Share Rewards',
    'Auxiliary Rewards',
    'Node Grant',
    'Equal Award',
    'Community subsidy',
    'Fees dividends',
  ],
};
