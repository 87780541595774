export default {
  description: 'No order information yet',
  deng: 'waiting for receipt',
  yi: 'Received coins',
  ordernum: 'order number',
  jiaoyinum: 'Number of transactions',
  price: 'transaction unit price',
  tprice: 'total transaction price',
  subtime: 'submission time',
  receipt: 'Billing message',
  relation: 'contact user',
  receipt_type: 'payment method',
  cash: 'cash transaction',
  cash2: 'cash',
  alipaynum: 'Alipay account',
  wechatnum: 'We chat number',
  banknum: 'Bank card number',
  unknow_name: 'unknown name',
  unknow_way: 'unknown way',
  unknow: 'unknown',
  // BuyBlanketOrder
  order_zong: 'total order number',
  order_price: 'unit price',
  order_num: 'quantity',
  order_xiane: 'limit',
  order_time: 'update time',
  order_cancel: 'Cancelled',
  order_xiajia: 'Temporarily removed',
  order_shangjia: 'continue to be listed',
  order_order: 'purchase order',
  order_xiangou: 'purchase limit',
  order_in: 'Incorrect amount entered',
  order_update: 'Update order',
  order_cancel_order: 'cancel order',
  order_cancel_tip: 'cancel prompt',
  order_whether: 'Can you cancel this acquisition order?',
  order_huifu: 'After cancellation, the order cannot be reinstated!',
  order_alter: 'Order modified successfully',
  order_warn: 'The amount entered is incorrect, please re-enter',
  order_quxiao: 'order has been cancelled',
  order_xiajia_tip: 'Removal Tips',
  order_msg1: 'After the transaction is temporarily removed, the external acquisition will be temporarily stopped，',
  order_msg2: 'Will this order be temporarily removed',
  order_xiajia2: 'take down',
  order_xiajia3: 'Order has been removed',
  order_shangjia_tip: 'Listing Tips',
  order_msg3: 'The order can be traded normally after being put on the shelf,',
  order_msg4: 'Whether to put this order on the shelf',
  order_shangjia2: 'on the shelf',
  order_suc: 'The order has been listed and can be traded normally',
  order_warn2: 'The price of the currency cannot be lower than CNY 5',
  order_warn3: 'The price of the currency cannot be higher than CNY 7.5',
  order_warn4: 'The maximum amount of USDT you can acquire cannot exceed the pledged amount',
  // BuyPending
  pending: 'No order information yet',
  pending_duifang: "The amount of the other party's pledge",
  pending_shoukuan: "Seller's payment method",
  pending_jianyan: 'Please verify the contract first',
  pending_xiangxi: 'details',
  pending_name: 'payee name',
  pending_account: 'Receiving account',
  pending_confirm: 'Confirm payment',
  pending_shangwei: 'Click before payment',
  pending_wancheng: '【I have completed the transfer】',
  pending_shiwei: 'will be considered malicious operation,',
  pending_eyi: 'Malicious actions freeze users',
  pending_jine: 'transfer amount',
  pending_me: 'I have confirmed',
  pending_zhuan: 'I have completed the transfer',
  pending_think: 'let me think again',
  pending_zhongcai: 'This order is under arbitration!',
  pending_heyue: 'Contract verification, please wait...',
  pending_heyue2: 'After the contract verification is passed, you can send money to the other party!',
  pending_yichang: 'order exception',
  pending_yue: 'The order balance in the contract is',
  pending_site: 'The address to which the order is bound is',
  pending_copy: 'Account has been copied',
  pending_error: 'Please try again later',
  // BuyReceivedCoin
  coin: 'add to blacklist',
  // SellAccomplish
  lish: 'paid for',
  lish_shensu: 'appeal',
  lish_error1: 'Error getting server data!',
  lish_warn1: 'The appeal function is not open yet, please be patient!',
  // SellBlankerOrder
  sorder: 'check',
  sorder_chushou: 'sale order',
  sorder_xianshou: 'Restricted',
  sorder_zhuijia: 'additional quantity',
  sorder_cancel: 'Cancel this sell order?',
  sorder_num: 'Please enter the number of cancellations',
  sorder_all: 'all',
  sorder_ipt: 'Please enter quantity',
  sorder_suc: 'Order quantity added successfully',
  sorder_input: 'Please enter 0',
  sorder_input2: 'quantity!',
  sorder_jianyan: 'Checking...',
  // SellPending
  spending: 'Release USDT',
  spending_dengdai: 'Waiting for the user to send money!',
}