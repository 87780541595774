export default {
  tags1:'交易总量',
  tags2:'总交易量',
  tags3:'万',
  tags4:'总交易人数',
  tags5:'人',
  tags6:'交易数据图表',
  tags7:'USDT购买出售情况',
  tags8:'交易量',
  tags9:'人数',
  title1:'交易数据',
  data1:["购买(USDT)", "出售(USDT)"],
  data2:'购买(USDT)',
  data3:'出售(USDT)'
}