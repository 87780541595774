export default {
  navbar: '收益明細',
  text: ['收益類型', '收益金額', '發放日期'],
  empty: '暫無收益數據',
  loading: {
    text: '加載中…',
    success: '加載成功',
    fail: '加載失敗',
  },
  list: [
    '質押收益',
    '分享獎勵',
    '輔助獎勵',
    '節點補助',
    '平級獎',
    '社區獎勵',
    '額外獎勵',
  ],
};
