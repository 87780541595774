export default {
  name: '姓 名',
  name_pla: '請輸入真實姓名',
  kaihu: '開戶銀行',
  kaihui_pla: '請輸入開戶銀行',
  banknum: '銀行卡號',
  banknum_pla: '請輸入銀行卡賬號',
  alipay: '支 付 寶',
  alipay_pla: '請輸入支付寶賬號',
  wechat: '微 信 號',
  wechat_pla: '請輸入微信賬號',
  bankaccount: '銀行賬號',
  renzheng: '我已認證核對',
  title: '設置收付款信息',
  msg: '名稱不能為空',
  msg2: '名稱需要兩位字符以上！',
  msg3: '請輸入正確的開戶銀行',
  msg4: '請輸入正確的銀行卡號碼',
  msg5: '請輸入正確的支付寶賬號',
  msg6: '請輸入正確的微信賬號',
  msg7: '提幣地址不能為空',
  msg8: '請輸入正確的提幣地址',
  msg9: '交易密碼不能為空',
  msg10: '交易密碼需要由字母和數字組成，且在8-16之間',
  warn: '暫不支持該收付款方式',
  toast: '銀行信息請填寫完整！',
  toast2: '請填寫最少一種支付方式',
  toast3: '請輸入銀行卡的完整信息',
  tip: '溫馨提示',
  msg11: '收付款信息涉及交易，請核對信息無誤之後再進行提交',
  ok: '好的',
  msg12: '請確認當前信息無誤',
  error: '設置失敗，請重試。 。 。',
  toast4: '添加付款方式成功！',
  // list
  list_title:'收付款信息',
  list_set:'設置收付款賬號',
  list_del:'刪除',
  list_kaiqi:'向買家僅展示已開啟的收款賬戶',
  list_any:'還沒有任何收付款方式',
  list_serch:'搜索',
  list_def:'默認貨幣',
  list_all:'全部',
  list_res:'資源未找到',
  list_pay:["銀行卡", "支付寶", "微信"],
  
}