export default {
  shouyi: '二期可支配收益',
  kapai: '卡牌',
  zhiya: '交易质押',
  bibizhiya: 'OTC 币币质押明细',
  tochakan: '去查看',
  zhuanbi: '链上理财赚币',
  shouyimingxi: '质押收益明细',
  tuiguang: '推广收益明细',
  wakuang: '流动性挖矿',
  tixian: '提现',
  chongzhi: '充值',
  youxiao_user: '有效用户',
  xinyong_node: '信用节点',
  shishi_node: '实时节点',
  zhongji_node: '中级节点',
  gaoji_node: '高级节点',
  youke: '游客',
  // mining
  mining_shouyi: '推广总收益',
  mining_zhitui: '直推收益',
  mining_jiantui: '间推收益',
  mining_shequ: '社区补贴',
  mining_jiedian: '节点补贴',
  mining_rule: '流动性挖矿规则',
  mining_p1: '质押EOTC\USDT交易对LP凭证6个月',
  mining_p2: '100-4999个双币质押LP凭证 正常年化收益',
  mining_p3: '5000-49999个双币质押LP凭证 1.2倍年化收益',
  mining_p4: '50000个及以上双币质押LP凭证 1.4倍年化收益',
  mining_p5: '年化收益计算按双币质押LP凭证个数乘以年化收益率，奖励EOTC代币',
  mining_p6: '质押EOTCUSDT交易对LP凭证12-36个月',
  mining_p7: '6个月收益48%',
  mining_p8: '12个月收益72%',
  mining_p9: '24个月收益100%',
  mining_p10: '收益根据二级市场价格自动调整',
  mining_p11: '流动性挖矿推广奖励',
  mining_p12: '质押EOTC\USDT交易对LP凭证6-24个月到DAPP，用户和上级获得EOTC代币奖励',
  mining_p13: '直推收益的20%',
  mining_p14: '间推收益的5%',
  mining_p15: '社区补贴 收益的1%向上分十代社区',
  mining_p16: '节点补贴 1、高级节点15%',
  mining_p17: '2、中级节点13%',
  mining_p18: '3、实时节点10%',
  mining_p19: '4、信用节点5%',
  // NFT
  nft_share: '分享奖励',
  nft_fuzhu: '辅助奖励',
  nft_shequ: '社区奖励',
  nft_manghe: '盲盒奖励(个)',
  nft_kapai: '卡牌奖励(个)',
  nft_eotc: 'EOTC资金池',
  nft_heyue: '合约分配',
  nft_usdt: 'USDT资金池',
  nft_jiaoyi: '交易手续费',
  // recharge
  recharge_type: '充值类别',
  recharge_net: '充值网络',
  recharge_site: '充值地址',
  recharge_num: '充值数量',
  recharge_pla: '请输入充值数量',
  recharge_wancheng: '完成充值',
  recharge_recode: '查看充值记录',
  recharge_data1: '充值功能尚未开放！',
  recharge_data2: '充值成功!',
  recharge_data3: '您充值的金额将在3分钟之内到账.',
  recharge_your: '您的',
  recharge_data4: '余额不足！',
  // rechargeRecord
  record_statu: '当前状态',
  record_date: '充值日期',
  record_not: '暂无收益数据',
  record_title: '充值记录',
  record_loading: '加载中',
  record_confing: '确认中',
  record_wancheng: '已完成',
  record_suc: '加载成功',
  record_fail: '加载失败',
  // Withdraw
  withdraw_type: '提现类别',
  withdraw_net: '网络类型',
  withdraw_site: '提现地址',
  withdraw_num: '提现数量',
  withdraw_pla: '请输入提现数量',
  withdraw_all: '全部',
  withdraw_fee: '手续费',
  withdraw_sub: '提交',
  withdraw_min: '最少提100',
  withdraw_time: '提币到账时间为T+1。',
  withdraw_warn: '功能暂未开放，请耐心等待。',
  withdraw_warn2: '您的EOTC余额不足！',
  withdraw_warn3: '您的USDT余额不足！',
  withdraw_load: '加载中...',
  withdraw_suc: '提现成功，您提现的金额将在5分钟之内到账',
  // WithdrawRecord
  wrecord_date: '提现日期',
  wrecord_record: '提现记录',
  wrecord_zhiya: '质押收益',
  wrecord_buzhu: '节点补助',
  wrecord_pingji: '平级奖',
  wrecord_ewai: '额外奖励',
  // zyzb
  zyzb_dingqi: '定期质押',
  zyzb_lixi: '利息收益',
  zyzb_tuijian: '推荐质押(人数)',
  zyzb_zongshu: '推荐质押总量(EOTC)',
  zyzb_rule: '盲盒赠送规则',
  zyzb_p1: '用户质押1000-1999EOTC赠送推荐人1个盲盒',
  zyzb_p2: '用户质押2000-2999EOTC赠送推荐人3个盲盒',
  zyzb_p3: '用户质押3000-3999EOTC赠送推荐人4个盲盒',
  zyzb_p4: '用户质押4000-4999EOTC赠送推荐人6个盲盒',
  zyzb_p5: '用户质押5000EOTC以上赠送推荐人10个盲盒',
  zyzb_p6: '定期质押六个月',
  zyzb_p7: '1000-1999EOTC赠送用户1个盲盒',
  zyzb_p8: '2000-2999EOTC赠送用户3个盲盒',
  zyzb_p9: '3000-3999EOTC赠送用户4个盲盒',
  zyzb_p10: '4000-4999EOTC赠送用户6个盲盒',
  zyzb_p11: '5000EOTC赠送用户10个盲盒',
  zyzb_p12: '5000EOTC以上每质押多500EOTC则加送用户1个盲盒',
  zyzb_p13: '定期质押十二个月',
  zyzb_p14: '1000-1999EOTC赠送用户2个盲盒',
  zyzb_p15: '2000-2999EOTC赠送用户5个盲盒',
  zyzb_p16: '3000-3999EOTC赠送用户7个盲盒',
  zyzb_p17: '4000-4999EOTC赠送用户10个盲盒',
  zyzb_p18: '5000EOTC赠送用户13个盲盒',
  zyzb_p19: '5000EOTC以上每质押多400EOTC则加送用户1个盲盒',
  zyzb_p20: '定期质押二十四个月',
  zyzb_p21: '1000-1999EOTC赠送用户3个盲盒',
  zyzb_p22: '2000-2999EOTC赠送用户6个盲盒',
  zyzb_p23: '3000-3999EOTC赠送用户9个盲盒',
  zyzb_p24: '4000-4999EOTC赠送用户12个盲盒',
  zyzb_p25: '5000EOTC赠送用户14个盲盒',
  zyzb_p26: '5000EOTC以上每质押多300EOTC则加送用户1个盲盒',
  zyzb_sub: '链上理财质押',
  zyzb_title: '链上质押赚币',
  // pledge(index)
  pledge_zong: '质押总额',
  pledge_ming: '质押明细',
  pledge_mian: '免手续费额度',
  pledge_zen: '赠送质押',
  pledge_shou: '手续费分红',
  pledge_nft: 'NFT权益卡',
  pledge_zen_nft: '赠送NFT卡牌',
  pledge_rule: '质押链上节点规则',
  pledge_jiaoyi: '交易用户',
  pledge_p1: '1、信用分10分以上',
  pledge_youxiao: '有效用户(满足以下条件之一)',
  pledge_p2: '1、质押100EOTC及以上',
  pledge_p3: '2、定期理财质押100EOTC及以上',
  pledge_p4: '3、持有EOTC NFT任意一张卡牌及以上',
  pledge_p5: '4、质押EOTC LP 100个及以上',
  pledge_p6: '5、EOTC去中心化OTC交易所30天内交易三次及以上',
  pledge_xinyong: '信用节点',
  pledge_p7: '1、需要至少有5个直推有效用户',
  pledge_p8: '2、质押代币数量5000个以上',
  pledge_p9: '3、团队有效用户90人',
  pledge_shishi: '实时节点',
  pledge_p10: '1、需要至少有7个直推有效用户',
  pledge_p11: '2、质押代币数量10000个以上',
  pledge_p12: '3、团队二条线有信用节点',
  pledge_p13: '4、团队有效用户300人',
  pledge_zhongji: '中级节点',
  pledge_p14: '1、需要至少有13个直推有效用户',
  pledge_p15: '2、质押代币数量前101-1100名',
  pledge_p16: '3、团队三条线有实时节点或实时节点永久分红权益卡',
  pledge_p17: '4、团队有效用户900人',
  pledge_p18: '5、至少质押代币数量50000个以上',
  pledge_gaoji: '高级节点',
  pledge_p19: '1、需要至少有19个直推有效用户',
  pledge_p20: '2、质押代币数量前100名',
  pledge_p21: '3、团队三条线有中级节点或中级节点永久分红权益卡',
  pledge_p22: '4、团队有效用户3000人',
  pledge_p23: '5、至少质押代币数量100000个',
  pledge_p24: '提示:今日链上理财赚币中定期质押(6个月、12个月、24个月)总计超过1000EOTC,次日即可减免25%的去中心化币币交易所手续费。',
  pledge_zhiya: '交易质押',
  pledge_shuhui: '赎回',
  pledge_lian: '链上理财赚币',
  pledge_otc: 'OTC质押',
  pledge_shouyi: '质押收益',
  // pledge(from)
  from_account: '质押账户',
  from_zong: 'EOTC总量',
  from_qixian: '质押期限',
  from_tab: '请选择',
  from_num: '质押数量',
  from_pla: '请输入质押数量',
  from_tip: '提示:质押6个月年化收益48%，质押12个月年化收益72%，质押24个月年化收益100%，质押36个月年化收益120%',
  from_node_type: '选择节点类型',
  from_type: '节点类型',
  from_columns: [
    "有效用户 100EOTC",
    "信用节点 5000EOTC",
    "实时节点 10000EOTC",
    "中级节点 50000EOTC",
    "高级节点 100000EOTC",
  ],
  from_column: ["6个月", "12个月", "24个月", "36个月"],
  from_youxiao: '请输入有效的质押数量',
  from_acc_buzu: '您的EOTC余额不足',
  from_fail: '质押失败！',
  from_suc: '质押成功！',
  from_months:'个月',
  from_cancel:'取消',
  // pledge(record)
  ple_record_leiji:'累计收益',
  ple_record_dangqian:'当前质押',
  ple_record_shuhui:'待赎回',
  ple_record_order:'订单编号',
  ple_record_daoqi:'质押到期',
  ple_record_yugu:'预估收益',
  ple_record_keshuhui:'到期可赎回',
  ple_record_title:'质押记录',
  ple_record_zhiyaing:'质押中',
  // pledge(shfrom)
  shfrom_shuhui:'质押赎回',
  shfrom_num:'赎回数量',
  shfrom_num2:'可赎回 0 EOTC',
  shfrom_tip:'赎回提示',
  shfrom_cai:'质押六个月以后才可以赎回',
  shfrom_iknow:'我知道了',
  
}