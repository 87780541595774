export default {
  arbitrationMsg: {
    title: "仲裁案",
    plaintiff: "原告",
    seller: "(卖家)",
    ticket: "票",
    defendant: "被告",
    Buyer: "(买家)",
    user_content: ["账户被冻结", "卖家未确认收款", "其他"],
    orders: "仲裁详情",
    notice_title: "仲裁结果",
    notice_bot: [
      "本次参与仲裁事件的仲裁员共计",
      "人,通过双方提交举证,",
      "位仲裁员判定被告dsads",
    ],
    detail: "详情",
    applicant: "申请人",
    reason: "申请原因",
    explain: "申请延期说明",
    time: "申请延期时间",
    day: "天",
    cancel: "取消原因",
    plain_evidence: "原告举证",
    defend_evidence: "被告举证",
    Adduce_evidence: "追加举证",
    Sponsor: "发起人",
    number: "编号",
    plain: "说明",
    Closure:
      "该仲裁案已结案，如有异议可在结案后七日内进入详情申请再仲裁，注意逾期将无法队此案进行再仲裁",
    again: "申请再次仲裁",
    messageType: [
      "申请延期",
      "追加举证",
      "发起重新举证",
      "取消仲裁",
      "结案通知",
    ],
  },
  index: {
    title: "仲裁案动态",
    messageType: ["申请", "通知"],
    reason: "近期订单中存在纠纷，对方向你发起仲裁，请及时前往处理",
    description: "暂无数据",
    before_second: "秒钟前",
    before_minute: "分钟前",
    before_day: "天前",
  },
};
