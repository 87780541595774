export default {
  important: {
    xi: '西',
    payment: '西紅柿首付',
    attention: '關注TA',
    yi_attention: '已關注',
    remove: '移除',
    remove_title: '移除黑名單',
    sure: '確認將該商家移除黑名單？',
  },
  index: {
    title: '關注',
    name: '姓名：李清茶',
    trading: '交易過',
    black: '黑名單',
    user_name: '張三',
  },
  merchant: {
    title: '商家主頁',
    recently: '最近上線時間',
    attention: '關注TA',
    yi_attention: '已關注',
    client: 'TA的委託單',
    buy: '購買',
    sell: '出售',
    listLoading: '加載中...',
    not_data: '暫無訂單',
    finished: '已經沒有更多了...',
    number: '數量',
    quota: '限額',
    price: '單價',
    hint: '溫馨提示',
    sure: '確認將該商家移除黑名單？',
    toast_title: '已將對方拉入黑名單！',
    toast_content: '將不在顯示該商家訂單！！！',
  },
};
