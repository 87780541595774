export default {
  label: [
    '总收益',
    '查看收益明细',
    '分享奖励',
    '辅助奖励',
    '节点补助',
    '平级奖',
    '社区补贴',
    '手续费分红',
  ]
}