import sign from "./sign"

export default {
  sign,
  text: [
    '采用严格的邀请注册制',
    '当前网络',
    '钱包地址',
    '输入邮箱地址',
    '发送验证码',
    '输入邮箱验证码',
    '填写邀请地址',
    '我已阅读并同意',
    '用户协议',
    '确定注册',
    '已有账户',
    '去登录'
  ]
}