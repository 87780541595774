export default {
  text: [
    '一期可提',
    '餘額',
    '釋放',
    '總數據',
    '一期',
    '收益及獎勵',
    '查看收益明細',
    '轉二期質押',
    '總量',
    '質押',
    '空投',
    '質押收益',
    '平級獎',
    '分享獎勵',
    '輔助獎勵',
    '節點補助',
  ],
};
