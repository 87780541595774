export default {
  text: [
    'One-phase withdrawal',
    'Balance',
    'Release',
    'Total data',
    'First phase',
    'Revenue and rewards',
    'View income details',
    'Transfer to 2-phase pledge',
    'Total amount',
    ' Staking',
    'airdrop',
    'staking income',
    'level reward',
    'sharing reward',
    'auxiliary reward',
    'node subsidy',
  ],
};
