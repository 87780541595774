export default {
  description: '暫無訂單信息',
  deng: '等待收幣',
  yi: '已收幣',
  ordernum: '訂單號',
  jiaoyinum: '交易數量',
  price: '交易單價',
  tprice: '交易總價',
  subtime: '提交時間',
  receipt: '收款信息',
  relation: '聯繫用戶',
  receipt_type: '收款方式',
  cash: '現金交易',
  cash2: '現金',
  alipaynum: '支付寶賬號',
  wechatnum: '微信號',
  banknum: '銀行卡號',
  unknow_name: '未知姓名',
  unknow_way: '未知方式',
  unknow: '未知',
  // BuyBlanketOrder
  order_zong: '總訂單號',
  order_price: '單價',
  order_num: '數量',
  order_xiane: '限額',
  order_time: '更新時間',
  order_cancel: '已取消',
  order_xiajia: '暫時下架',
  order_shangjia: '繼續上架',
  order_order: '收購單',
  order_xiangou: '限購',
  order_in: '輸入金額不正確',
  order_update: '更新訂單',
  order_cancel_order: '取消訂單',
  order_cancel_tip: '取消提示',
  order_whether: '是否取消該收購訂單？',
  order_huifu: '取消後，訂單不能恢復！',
  order_alter: '訂單修改成功',
  order_warn: '輸入金額數量不正確，請重新輸入',
  order_quxiao: '訂單已經取消',
  order_xiajia_tip: '下架提示',
  order_msg1: '暫時下架交易後會暫時停止對外收購，',
  order_msg2: '是否暫時下架該筆訂單',
  order_xiajia2: '下架',
  order_xiajia3: '訂單已下架',
  order_shangjia_tip: '上架提示',
  order_msg3: '上架後訂單可正常進行交易，',
  order_msg4: '是否上架該筆訂單',
  order_shangjia2: '上架',
  order_suc: '訂單已上架，可正常進行交易',
  order_warn2: '該貨幣價格不能低於 5 CNY',
  order_warn3: '該貨幣價格不能高於 7.5 CNY',
  order_warn4: '您最高收購 USDT 的數量不能超過質押的數量',
  // BuyPending
  pending: '暫無訂單信息',
  pending_duifang: '對方質押數量',
  pending_shoukuan: '賣家收款方式',
  pending_jianyan: '請先校驗合約',
  pending_xiangxi: '詳細信息',
  pending_name: '收款方姓名',
  pending_account: '收款賬號',
  pending_confirm: '確認已付款',
  pending_shangwei: '尚未付款就點擊',
  pending_wancheng: '【我已完成轉賬】',
  pending_shiwei: '將被視為惡意操作，',
  pending_eyi: '惡意操作會凍結用戶',
  pending_jine: '轉賬金額',
  pending_me: '我已確認',
  pending_zhuan: '我已完成轉賬',
  pending_think: '我再想想',
  pending_zhongcai: '該訂單正在仲裁中！',
  pending_heyue: '合約校驗中，請等待...',
  pending_heyue2: '合約校驗通過,可以給對方進行匯款！',
  pending_yichang: '訂單異常',
  pending_yue: '合約中訂單餘額為',
  pending_site: '訂單綁定的地址為',
  pending_copy: '賬號 已復制',
  pending_error: '請稍後重試',
  // BuyReceivedCoin
  coin: '加入黑名單',
  // SellAccomplish
  lish: '已收款',
  lish_shensu: '申訴',
  lish_error1: '獲取服務端數據錯誤！',
  lish_warn1: '申訴功能暫未開放，請耐心等待！',
  // SellBlankerOrder
  sorder:'校驗',
  sorder_chushou:'出售單',
  sorder_xianshou:'限售',
  sorder_zhuijia:'追加數量',
  sorder_cancel:'是否取消該出售訂單？',
  sorder_num:'請輸入取消數量',
  sorder_all:'全部',
  sorder_ipt:'請輸入數量',
  sorder_suc:'訂單數量追加成功',
  sorder_input:'請輸入0',
  sorder_input2:'的數量！',
  sorder_jianyan:'校驗中...',
  // SellPending
  spending:'釋放 USDT',
  spending_dengdai:'等待用戶進行匯款！',
}