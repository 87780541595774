export default {
  order: {
    sell: "出售",
    purchase: "收購",
    verify: "校驗",
    canceled: "已取消",
    copy: "訂單編號 已復制",
    number: "訂單編號",
    price: "價格",
    count: "數量",
    service_charge: "手續費",
    total: "總金額",
    show: "展示完整信息",
    name: "商家姓名",
    way: "聯繫方式",
    phone: "電話號碼 複製成功",
    select: "選擇收款方式",
    may: "可現金交易",
    deal: "現金交易",
    self: "請使用本人實名賬戶進行收款",
    generate: ",否則會導致訂單失敗且賬號存在被凍結風險",
  },
  comOrder: {
    sell: "出售",
    buyer: "聯繫買家",
    purchase: "收購",
    seller: "聯繫賣家",
    copy: "訂單編號 已復制",
    number: "訂單編號",
    price: "價格",
    count: "數量",
    service_charge: "手續費",
    total: "總金額",
    show: "展示完整信息",
    name: "商家姓名",
    way: "聯繫方式",
    phone: "電話號碼 複製成功",
    plate: "電話號碼",
    toast: "複製失敗，請稍後重試",
  },
  undone: {
    sell: "出售",
    purchase: "收購",
    verify: "校驗",
    Velocity: "去轉幣",
    payment: "去付款",
    Stay: "待放幣",
    wait: "等待商家付款",
    put: "待收幣",
    copy: "訂單編號 已復制",
    number: "訂單編號",
    price: "價格",
    count: "數量",
    service_charge: "手續費",
    total: "總金額",
    show: "展示完整信息",
    name: "商家姓名",
    way: "聯繫方式",
    phone: "電話號碼 複製成功",
    may: "可現金交易",
    pattern: "付款方式",
    deal: "交易",
    cash: "現金",
    alipay: "支付寶",
    wechat: "微信",
    account: "收款賬號",
    select: "選擇收款方式",
    cash_deal: "現金交易",
    self: "請使用本人實名賬戶進行收款",
    generate: ",否則會導致訂單失敗且賬號存在被凍結風險",
    toast: [
      "訂單已過期，系統將自動取消。",
      "操作過於頻繁，請稍候再試...",
      "已自動取消，到期訂單",
    ],
  },
  refunds: {
    sell: "出售",
    purchase: "收购",
    verify: "校验",
    Velocity: "去转币",
    payment: "去付款",
    take_out: "取出",
    number: "订单编号",
    price: "价格",
    count: "数量",
    service_charge: "手续费",
    total: "总金额",
    show: "展示完整信息",
    name: "商家姓名",
    way: "联系方式",
    phone: "电话号码 复制成功",
    copy: "订单编号 已复制",
    may: "可现金交易",
    pattern: "付款方式",
    deal: "交易",
    cash: "现金",
    alipay: "支付宝",
    wechat: "微信",
    account: "收款账号",
    toast: [
      "订单取消成功！",
      "正在从合约中撤出 USDT。。。",
      "订单已过期，系统将自动取消。",
      "USDT 已撤出,订单已取消！",
      "操作过于频繁，请稍候再试...",
      "已自动取消，到期订单",
    ],
    dialog: ["提取 USDT", "恶意操作将会被 【限制下单】", "确定从该订单中撤回"],
    purchase: "收購",
    verify: "校驗",
    Velocity: "去轉幣",
    payment: "去付款",
    take_out: "取出",
    number: "訂單編號",
    price: "價格",
    count: "數量",
    service_charge: "手續費",
    total: "總金額",
    show: "展示完整信息",
    name: "商家姓名",
    way: "聯繫方式",
    phone: "電話號碼 複製成功",
    copy: "訂單編號 已復制",
    may: "可現金交易",
    pattern: "付款方式",
    deal: "交易",
    cash: "現金",
    alipay: "支付寶",
    wechat: "微信",
    account: "收款賬號",
    toast: [
      "訂單取消成功！",
      "正在從合約中撤出 USDT。。。",
      "訂單已過期，系統將自動取消。",
      "USDT 已撤出,訂單已取消！",
      "操作過於頻繁，請稍候再試...",
      "已自動取消，到期訂單",
    ],
    dialog: ["提取 USDT", "惡意操作將會被 【限制下單】", "確定從該訂單中撤回"],
  },
  full: {
    unfinished: "未完成",
    finished: "已完成",
    other: "其他订单",
  },
  empty: {
    not_data: "暂无数据",
    other: "其他訂單",
  },
  empty: {
    not_data: "暫無數據",
  },
};
