export default {
  title: ['当前有', '笔订单待处理'],
  handle: '去处理',
  text: ['数量', '限额', '单价'],
  toast: {
    error: [
      '需要实名认证才能进行交易',
      '请您先完成实名认证！',
      '您已存在一笔正在进行的购买订单',
      '处理完成后方可继续交易！',
      '您已连续多次撤销订单，请明天再下单！',
      '您积分已不足',
      '无法购买！',
      '您没有填写该收付款方式',
      '目前暂时不支持'
    ]
  },
  loading: {
    text: '加载中…',
    finishedText: '已经没有更多了…'
  }
}