export default {
  tags1: 'Team size',
  tags2: 'Direct push number',
  tags3: 'valid node',
  tags4: 'Direct push performance',
  tags5: 'total performance',
  tags6: 'Name',
  tags7: 'not certified',
  tags8: 'Direct push node',
  tags9: 'Node type',
  tags10: 'Node level',
  tags11: 'registration date',
  tags12: 'more nodes',
  tags13: 'No team members',
  tags14: 'Phone number',
  tags15: 'Mail',
  tags16: 'One-stage pledge',
  tags17: 'transfer money to him',
  tags18: 'To view more members, please contact EOTC DAO',
  data1: 'Not pledged',
  // more
  tags19: 'Please enter the search keyword',
  tags20: 'Direct push node',
  node_type: 'Node type',
  node_grade: 'Node level',
  more_date: 'registration date',
  more_eotc: 'EOTC pledge',
  more_usdt: 'USDT balance',
  more_node: 'more nodes',
  // record
  record_tags1: 'Transfer in (out) UID',
  record_tags2: 'USDT quantity',
  record_date: 'transfer date',
  record_not_more: 'no more',
  record_data1: 'transfer record',
  // transfer
  transfer_wallet: 'wallet address',
  transfer_wallet_tab: 'Click to select wallet address',
  transfer_currency: 'Select currency',
  transfer_currency_tab: 'Click to select currency',
  transfer_accounts: 'Number of transfers',
  transfer_record: 'View transfer history',
  transfer_data1: 'transfer',
  transfer_data2: 'transferable',
  transfer_data3: 'Please select a currency first!',
  transfer_data4: 'Please enter a valid quantity!',
  transfer_data5: 'Your wallet balance is low!',
}