import sign from './sign';

export default {
  sign,
  text: [
    '採用嚴格的邀請註冊制',
    '當前網絡',
    '錢包地址',
    '輸入郵箱地址',
    '發送驗證碼',
    '輸入郵箱驗證碼',
    '填寫邀請地址',
    '我已閱讀並同意',
    '用戶協議',
    '確定註冊',
    '已有賬戶',
    '去登錄',
  ],
};
