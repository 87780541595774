export default {
  text: [
    '购买1',
    '单价',
    '刷新'
  ],
  tab: [
    '按金额购买',
    '按数量购买'
  ]
}