export default {
  // index页面  seller_buyBill页面
  index: {
    MerchanInfo: '申诉',
    state1: '已付款',
    Putthecoin1: '催放币',
    Putthecoin2: '已催放币',
    state2: '待付款',
    state3: '去付款',
    text1: '转账请认准订单详情中展示的卖家收款账号，或卖家分享',
    text2: '的收款账号卡片。其余卖家私聊发送的账号信息一律不要 转账。',
    online: '连线中',
    send: '发送',
    please: '请上传图片',
    uploading: "上传",
    ospf: '第',
    page: '页',
    empty: '发送内容不能为空',
    atleast: '请至少上传一张图片！',
    loading: '加载中',
    fail: '图片上传失败，请稍后上传！',
    runningwater: '请联系卖家通过您的流水审查！',
    sendeamil: '已发送邮件至对方邮箱！',
    putmoney: '老板您好，我已付款，请尽快放币！！',
    urge: '请不要频繁催促对方！！！',
    payVal: '未知&未知&未知&',
    alreadypassed: '我已通过您的流水审查，您可以进行下一步付款',
    err: '连接错误  error',
    warn: '连接错误，断开连接。。',
    first: '首次刷新数据',
    contactway: '联系方式'

  },
  // seller-bill页面
  seller_bill: {
    state1: '对方待付款',
    state2: '催付款',
    state3: '已催付款',
    cancel: '取消订单',
    state4: '对方已付款',
    text: '流水审查',
    passed: '账户审核通过',
    tips: ' 为了防止黑钱流通、账号被封，',
    tips2: '只有您对对方账户进行通过流水审查之后，对方才可看到您的收付款账号进行汇款。',
    cancelorder: '正在取消订单。。。',
    canorder: '取消订单',
    message: "<span class='activeInfo'>恶意取消订单会被 【限制交易】</span> <br />确定取消订单吗？",
    cancleerr: "订单取消成功",
    payment: '老板您好，请尽快完成订单支付！',
    title: '是否通过对方的流水审查',
    text2: '我在想想',
    text3: '通过',
    text4: "流水审查已通过",
    text5: "等待买家汇款",
  }

}